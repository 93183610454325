import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const ContactPage = () => (
    <Layout>
        <SEO title="Contact Us" />
        <section className="hero is-primary is-fullheight-with-navbar">
            <div className="hero-body">
                <div className="container">
                    <div className="columns">
                        <div className="column is-one-third">
                            <h1 className="title has-text-warning">Want to join the team?</h1>
                            <h1 className="subtitle">Apply Today!</h1>
                            <p>
                                Join us at GalaxyWorks - a member of the most popular open source bioinformatics
                                ecosystem in the world! Check out our job postings here, or reach out directly.
                            </p>
                        </div>
                        <div className="column">
                            <div className="container m-l-lg">
                                <h2 className="title">Computational Biologist</h2>
                                <h2 className="subtitle">Remote, but in the US</h2>
                                <div>
                                    <p>
                                        You are a creative and pragmatic individual who wants to apply technical
                                        expertise while continuing to learn new skills and acquire knowledge in
                                        bioinformatics. You will be challenged with solving practical tasks for our
                                        clients that span different analysis techniques, require understanding of tools,
                                        data, and biology.
                                    </p>
                                    <h2 className="subtitle m-t-lg">Responsibilities</h2>
                                    <ul className="m-l-lg" style={{ listStyleType: "square" }}>
                                        <li>
                                            Execute on client bioinformatics data analysis projects across a wide
                                            variety of domains in genomics and bioinformatics
                                        </li>
                                        <li>
                                            Implement and/or tailor best practices, novel pipelines and data analysis
                                            methods
                                        </li>
                                        <li>
                                            Maintenance of and improvements to existing client genomic pipelines,
                                            bioinformatics tools, and related software and systems
                                        </li>
                                        <li>Scope work for new and existing client projects</li>
                                    </ul>
                                    <h2 className="subtitle m-t-lg">Preferred Qualifications</h2>
                                    <ul className="m-l-lg" style={{ listStyleType: "square" }}>
                                        <li>
                                            Experience developing pipelines and analyzing data in genomics and biology
                                        </li>
                                        <li>Familiarity with tools and data types</li>
                                        <li>Competencies in Python, XML, shell scripting, Conda</li>
                                        <li>B.S. or higher degree in Computational Biology or a related field</li>
                                        <li>
                                            Comfort working in a startup environment, where ambiguity can be high and
                                            priorities change often
                                        </li>
                                    </ul>
                                    <h2 className="subtitle m-t-lg">Compensation</h2>
                                    <ul className="m-l-lg" style={{ listStyleType: "square" }}>
                                        <li>Salary range between $50-90k depending on qualifications and experience</li>
                                        <li>Equity in GalaxyWorks</li>
                                        <li>Health, dental, and vision insurance that is 100% employer paid</li>
                                        <li>
                                            Paid time off including 10 public holidays and 25 days of combined vacation
                                            and sicktime
                                        </li>
                                    </ul>
                                    <h2 className="subtitle m-t-lg">We are looking for</h2>
                                    <ul className="m-l-lg" style={{ listStyleType: "square" }}>
                                        <li>A self-starter, self-motivated, problem solver</li>
                                        <li>Someone who will see a solution through to completion</li>
                                        <li>An independent learner</li>
                                        <li>
                                            A team player that is communicative and open to receiving and providing
                                            feedback
                                        </li>
                                    </ul>

                                    <p className="m-t-lg">
                                        Apply by emailing your 1-page resume to{" "}
                                        <a className="has-text-weight-bold" href="mailto:recruiting@galaxyworks.io">
                                            recruiting@galaxyworks.io
                                        </a>
                                        .
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
);

export default ContactPage;
